import React, { Component } from "react";
import ErrorBoundaryPage from "src/components/General/ErrorBoundaryPage";
import { LogHelper } from "src/helpers/LogHelper";
import { globalHistory, HistoryUnsubscribe } from "@reach/router";
import { LogMsg } from "ps-shared";

interface ErrorBoundaryState {
  hasError: boolean;
  info: string;
  error: string;
}
// tslint:disable-next-line:no-empty-interface
export interface ErrorBoundaryProps {
  children: any;
  pathName: string;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  private historyListener: HistoryUnsubscribe;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      info: "",
      error: "",
    };
  }

  public componentDidMount() {
    this.historyListener = globalHistory.listen(() => {
      if (this.state.hasError) {
        this.setState({
          hasError: false,
        });
      }
    });
  }

  public componentDidCatch(error: any, info: any) {
    this.setState({
      hasError: true,
      info,
      error,
    });
    console.error(error);
    console.error(info);
    const browserSpecs = (() => {
      const ua = navigator.userAgent;
      let tem;
      let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: "IE", version: tem[1] || "" };
      }
      if (M[1] === "Chrome") {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return { name: tem[1].replace("OPR", "Opera"), version: tem[2] };
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
      // eslint-disable-next-line no-cond-assign
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
      return { name: M[0], version: M[1] };
    })();
    const logMsg = {
      message: error.message,
      data: {
        stack: error.stack,
        browser: browserSpecs,
        info,
      },
      name: this.props.pathName,
      level: "ERROR",
    } as LogMsg;
    LogHelper.publish(logMsg);
  }

  public componentWillUnmount() {
    if (this.historyListener) {
      // unsubscribe
      this.historyListener();
    }
  }

  public render() {
    return this.state.hasError ? <ErrorBoundaryPage /> : this.props.children;
  }
}

export default ErrorBoundary;
