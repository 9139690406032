import React from "react";
import { Navbar, NavItem } from "reactstrap";
import { Link } from "gatsby";
import menus from "./menu.json";

export interface DesktopMenuProps {
  topStripDisplay: boolean;
}

export const DesktopMenu: React.FunctionComponent<DesktopMenuProps> = (props: DesktopMenuProps) => {
  const [topMenu, setTopMenu] = React.useState();
  const [subMenu, setSubMenu] = React.useState(false);
  const toggleSubMenu = (hover: boolean, menu: string | null): void => {
    setSubMenu(hover);
    setTopMenu(hover ? menu : null);
    if (hover === true) {
      document.querySelectorAll("html")[0].style.overflow = "hidden";
    } else {
      document.querySelectorAll("html")[0].style.overflow = "";
    }
  };

  return (
    <div className={"desktop-nav"}>
      <ul className={`navbar-nav ${subMenu ? "menu-open" : ""}`}>
        {/*
            First Level Menu
            */}
        {menus.map((m, i) => {
          return (
            <NavItem
              key={i}
              className={"nav-providers"}
              style={{ padding: "23px 5px" }}
              onMouseEnter={(): void => toggleSubMenu(true, m)}
              onMouseLeave={(): void => toggleSubMenu(false, m)}
            >
              <a
                className={`alink ${topMenu?.title === m.title ? "active" : ""}`}
                onClick={(): void => toggleSubMenu(true, m)}
              >
                {m.title}
              </a>
            </NavItem>
          );
        })}
      </ul>
      {/*
      Second Level Menu
      */}
      <div className={`${subMenu ? "visible" : "visibility-hidden"}`}>
        <Navbar
          className={`fixed-top d-flex flex-row justify-content-start desktop-menu ${
            props.topStripDisplay ? "top-strip-open" : "top-strip-closed"
          }`}
        >
          <div
            className="menu pt-3"
            onMouseEnter={(): void => toggleSubMenu(true, topMenu)}
            onMouseLeave={(): void => toggleSubMenu(false, topMenu)}
          >
            <div>
              <ul className="navbar-nav">
                {topMenu &&
                  topMenu.sub.map((m, i) => {
                    if (m.sub) {
                      return (
                        <NavItem key={i}>
                          <div className="fw500">
                            <Link to={m.link} onClick={(): void => toggleSubMenu(false, null)}>
                              {m.title}
                            </Link>
                          </div>
                          {m.sub.map((ms, index: number) => {
                            return (
                              <div className="sub" key={index}>
                                <Link to={ms.link} onClick={(): void => toggleSubMenu(false, null)}>
                                  {ms.title}
                                </Link>
                              </div>
                            );
                          })}
                        </NavItem>
                      );
                    }
                    return (
                      <NavItem key={i} className={"nav-providers"}>
                        <Link onClick={(): void => toggleSubMenu(false, null)} to={m.link}>
                          {m.title}
                        </Link>
                        {m.body && <p>{m.body}</p>}
                      </NavItem>
                    );
                  })}
              </ul>
            </div>
          </div>
        </Navbar>
      </div>
    </div>
  );
};
