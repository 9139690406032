interface Item {
  value: string;
  d: number;
}
export default class LocalStorage {
  public static save(key: string, value: string): void {
    if (!this.lsAvailable()) {
      return;
    }
    if (typeof window !== "undefined") {
      const sess = {
        value,
        d: Date.now(),
      } as Item;
      window.localStorage.setItem(key, JSON.stringify(sess));
    }
  }

  public static remove(key: string): void {
    if (!this.lsAvailable()) {
      return;
    }
    if (typeof window !== "undefined") {
      window.localStorage.removeItem(key);
    }
  }

  // eslint-disable-next-line consistent-return
  public static get(key: string, maxAgeSeconds: number | null = null): string {
    if (!this.lsAvailable()) {
      return null;
    }
    if (typeof window !== "undefined") {
      const item = window.localStorage.getItem(key);
      const sess = JSON.parse(item) as Item;
      if (maxAgeSeconds && sess) {
        if (Date.now() - sess.d > maxAgeSeconds * 1000) {
          return null;
        }
      }
      return sess ? sess.value : null;
    }
  }

  // eslint-disable-next-line consistent-return
  private static lsAvailable(): boolean {
    try {
      if (window.localStorage !== undefined) {
        return true;
      }
      return false;
    } catch (e) {
      console.error("Local Storage is not available! Psychedelic Support may not function correctly, re-enable your cookies.");
    }
  }
}
