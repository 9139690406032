import React, { useEffect } from "react";
import { checkout, CheckoutStore } from "src/stores/checkoutStore";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBag } from "@fortawesome/free-solid-svg-icons";

export interface CartProps {
  toggleCb?: () => void;
  calcCb?: () => void;
}
export const Cart: React.FunctionComponent<CartProps> = (props: CartProps) => {
  let cartSub: any;
  const [cartCount, setCartCount] = React.useState(null);
  useEffect(() => {
    async function getCart(): Promise<void> {
      const client = new CheckoutStore();
      await client.getCart();
    }
    getCart();
    cartSub = checkout.subscribe((cart) => {
      if (cart && cart.lineItems) {
        let items = 0;
        // eslint-disable-next-line no-return-assign
        cart.lineItems.forEach((li) => (items += li.quantity));
        setCartCount(items);
        if (props.calcCb) {
          props.calcCb();
        }
      }
    });
    return () => {
      // Anything in here is fired on component unmount.
      if (cartSub) {
        cartSub.unsubscribe();
      }
    };
  }, []);

  return (
    <div className="nav-cart cursor-pointer">
      {cartCount > 0 && (
        <>
          {/*
          Desktop
        */}
          <div className="d-none d-lg-inline">
            <Link to="/cart/">
              <FontAwesomeIcon icon={faShoppingBag} />
              <span className="counter">{cartCount}</span>
            </Link>
          </div>
          {/*
          mobile
        */}
          <div className="d-block d-lg-none pt-2 pb-2 mb-3">
            <Link to="/cart/" onClick={props.toggleCb}>
              <FontAwesomeIcon icon={faShoppingBag} />
              <span className="ms-2  counter">
                You have {cartCount}
                {cartCount > 1 ? " items" : " item"} in your bag
              </span>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};
