import axios from "axios";
import { iProfileType } from "ps-shared";

const api = process.env.PSYSUP_API;

// eslint-disable-next-line import/prefer-default-export
export class PublicService {
  public static profileContact(slug: string, webContactRequest: any, token: string) {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(`${api}/profiles/${slug}/contact`, {
          formValues: webContactRequest,
          token,
        })
        .then(() => {
          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static professionalConnect(slug: string, webContactRequest: any): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${api}/profiles/${slug}/contact/pc`, {
          formValues: webContactRequest,
        })
        .then(() => {
          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static joinNetworkContact(joinContact: any, token: string) {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(`${api}/marketing/join`, {
          formValues: joinContact,
          token,
        })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static subscribeEmailMarketing(subscribeEmail: any, token: string) {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(`${api}/marketing/lists/email`, {
          formValues: subscribeEmail,
          token,
        })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static subscribeClinicalTrial(subscribeClinicalTrial: any, token: string) {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(`${api}/marketing/lists/clinical-trial`, {
          formValues: subscribeClinicalTrial,
          token,
        })
        .then(() => {
          resolve(null);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static getProfileJson(profileType: string, slug: string): Promise<iProfileType> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${api}/profiles/${profileType}/${slug}`, { headers: { Accept: "application/json" } })
        .then((response: any) => {
          resolve(response.data.profile);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
