import React from "react";
import { Row, Col, Input, InputGroupText, InputGroup, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCircleNotch, faTimes } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga";

export interface SearchComponentProps {
  searchCb: (s: string) => void;
  input: string;
  inputPlaceholder?: string;
  loading: boolean;
  clearCb: () => void;
  location: string;
}

export const SearchComponent: React.FunctionComponent<SearchComponentProps> = (props: SearchComponentProps) => {
  const [searchVal, setSearchVal] = React.useState(props.input);
  const search = (): void => {
    props.searchCb(searchVal);
    ReactGA.event({
      category: "search",
      action: props.location,
      label: searchVal,
    });
  };
  React.useEffect(() => {
    setSearchVal(props.input);
  }, [props.input]);

  React.useEffect(() => {
    const onKeyDown = (e: any): void => {
      if (e.key === "Enter") {
        e.preventDefault();
        props.searchCb(e.target.value);
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return (): void => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  const setInput = (e: any): void => {
    setSearchVal(e.target.value);
  };
  const clear = (): void => {
    // we will set the value just in case it was already empty from the parent
    setSearchVal("");
    props.clearCb();
  };
  return (
    <div>
      <Row>
        <Col>
          <Label className="screen-reader-only">Search Psychedelic Support</Label>
          <InputGroup key={props.input}>
            <Input
              type="text"
              value={searchVal}
              placeholder={props.inputPlaceholder ? props.inputPlaceholder : "Search Psychedelic Support"}
              onInput={setInput}
            />
            <InputGroupText className="border-0">
              {searchVal && (
                <FontAwesomeIcon icon={faTimes} className="cursor-pointer" onClick={clear}></FontAwesomeIcon>
              )}
            </InputGroupText>
            <InputGroupText className="border-0">
              {props.loading === true && <FontAwesomeIcon icon={faCircleNotch} className="fa-spin"></FontAwesomeIcon>}
              {props.loading === false && <FontAwesomeIcon icon={faSearch} onClick={search} />}
            </InputGroupText>
          </InputGroup>
        </Col>
      </Row>
    </div>
  );
};
