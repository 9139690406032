import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

export interface SEOProps {
  title: string;
  description: string;
  image?: string;
  pathname: string;
  article?: boolean;
  schema?: any;
  canonical?: string;
}

const SEO: React.FunctionComponent<SEOProps> = ({
  title,
  description,
  image,
  pathname,
  article,
  schema,
  canonical,
}) => (
  <StaticQuery
    query={graphql`
      query SEO {
        site {
          siteMetadata {
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            siteUrl: url
            defaultImage: image
            twitterUsername
          }
        }
      }
    `}
    render={({
      site: {
        siteMetadata: { defaultTitle, titleTemplate, defaultDescription, siteUrl, defaultImage, twitterUsername },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${image ? siteUrl + image : siteUrl + defaultImage}`,
        url: canonical || `${siteUrl}${pathname || "/"}`,
      };
      return (
        <>
          <Helmet title={`${seo.title}`} titleTemplate={titleTemplate}>
            <link rel="canonical" href={seo.url} />
            <meta
              name="robots"
              content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
            />
            <meta
              name="googlebot"
              content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
            />
            <meta
              name="bingbot"
              content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
            />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && <meta property="og:type" content="article" />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && <meta property="og:description" content={seo.description} />}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && <meta name="twitter:description" content={seo.description} />}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            {schema && <script type="application/ld+json">{JSON.stringify(schema)}</script>}
          </Helmet>
        </>
      );
    }}
  />
);
export default SEO;
