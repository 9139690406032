import React, { ReactNode } from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
// reactstrap components
import { Navbar, NavItem, Container } from "reactstrap";

import { PresentationStore } from "src/stores/presentation-store";
import logo from "src/assets/img/logo.svg";
import { TopStripPromotion } from "./TopStripPromotion";
import { Cart } from "./Cart";
import { MobileMenu } from "./Menus/MobileMenu";
import { DesktopMenu } from "./Menus/DesktopMenu";
import { MobileMenuFooter } from "./Menus/MobileMenuFooter";
import { VisMenu } from "./Menus/VisMenu";
import { UserDesktop } from "./Menus/UserDesktop";

export enum Menu {
  GetTherapy = "get-therapy",
  Education = "education",
  Articles = "articles",
}

interface TopNavProps {
  stickyNav: boolean;
  toggleSearchCb: () => void;
}
interface TopNavState {
  authorized: boolean;
  modal: boolean;
  mobileNavOpen: boolean;
  userType: string;
  searchNavOpen: boolean;
  topStripDisplay: boolean;
  wrapperNavH: number;
  topMenu: Menu;
  desktopNavHover: boolean;
}
export class TopNav extends React.Component<TopNavProps, TopNavState> {
  private topStripRef = React.createRef();

  private navRef = React.createRef<HTMLDivElement>();

  constructor(props: TopNavProps) {
    super(props);
    this.state = {
      authorized: false,
      modal: false,
      mobileNavOpen: false,
      userType: undefined,
      searchNavOpen: false,
      topStripDisplay: true,
      wrapperNavH: 70 + 50, // the navbar height + top strip
      desktopNavHover: false,
      topMenu: null,
    };
    this.toggleNavSearch = this.toggleNavSearch.bind(this);
    this.topStripClose = this.topStripClose.bind(this);
    this.setNavHeight = this.setNavHeight.bind(this);
    this.toggleDesktopNavHover = this.toggleDesktopNavHover.bind(this);
    this.toggleMobileNavOpen = this.toggleMobileNavOpen.bind(this);
  }

  public componentDidMount() {
    this.setNavHeight();
    console.log("public nav");
  }

  public setNavHeight(): void {
    let topStripH = 0;
    let navH = 0;
    if (this.navRef && this.navRef.current) {
      navH = (this.navRef.current as any).getBoundingClientRect().height;
    }
    if (this.topStripRef && this.topStripRef.current) {
      topStripH = (this.topStripRef.current as any).getBoundingClientRect().height;
    }
    PresentationStore.setNavHeight(navH + topStripH);
    this.setState({
      wrapperNavH: navH + topStripH,
    });
  }

  public toggleNavSearch = (): void => {
    this.setState({
      searchNavOpen: !this.state.searchNavOpen,
    });
  };

  public topStripClose = (): void => {
    this.setState({
      topStripDisplay: false,
    });
    this.topStripRef = null;
    this.setNavHeight();
  };

  public toggleDesktopNavHover = (hover: boolean): void => {
    this.setState({ desktopNavHover: hover });
  };

  public toggleMobileNavOpen = (): void => {
    this.setState({ mobileNavOpen: !this.state.mobileNavOpen });
  };

  // tslint:disable:max-line-length
  public render(): ReactNode {
    return (
      <div style={{ height: `${this.state.wrapperNavH}px` }} className="wrapper-navbar">
        <VisMenu />
        <TopStripPromotion
          innerRef={this.topStripRef}
          display={this.state.topStripDisplay}
          closeCb={this.topStripClose}
          sticky={this.props.stickyNav === true}
        />
        <Navbar
          expand="lg"
          className={`${this.props.stickyNav === true ? "fixed-top" : "abs-top"} 
           navbar-white
           ${this.state.topStripDisplay ? "top-strip-open" : "top-strip-closed"}`}
        >
          <Container
            className="d-flex w100"
            onMouseEnter={() => this.toggleDesktopNavHover(true)}
            onMouseLeave={() => this.toggleDesktopNavHover(false)}
          >
            <div style={{ width: "100%" }} ref={this.navRef}>
              {/*
              desktop nav
              */}
              <div className="d-none d-lg-inline">
                <div className="top-nav-lg d-flex justify-content-between align-items-center">
                  <div className="nav-block">
                    <DesktopMenu topStripDisplay={this.state.topStripDisplay} />
                  </div>
                  <div className="nav-block text-center">
                    <Link to="/">
                      <img
                        src={logo}
                        alt="Psychedelic Support Logo"
                        title="Go to Psychedelic Support Home"
                        style={{ height: "42px" }}
                      />
                    </Link>
                  </div>
                  <div className="nav-block">
                    <ul className="navbar-nav">
                      <NavItem className="nav-cart me-2">
                        <Cart />
                      </NavItem>
                      <NavItem className="nav-providers me-2">
                        <FontAwesomeIcon
                          className="cursor-pointer"
                          icon={faSearch}
                          onClick={this.props.toggleSearchCb}
                        />
                      </NavItem>
                      <NavItem className="nav-login">
                        <UserDesktop />
                      </NavItem>
                    </ul>
                  </div>
                </div>
              </div>
              {/*
              mobile nav
              */}
              <div className="d-lg-none d-flex">
                <div className="top-nav d-lg-none d-flex flex-row justify-content-between align-items-center">
                  <div>
                    <Link to="/">
                      <img
                        src={logo}
                        alt="Psychedelic Support Logo"
                        title="Go to Psychedelic Support Home"
                        style={{ height: "42px" }}
                      />
                    </Link>
                  </div>
                  <MobileMenu
                    topStripDisplay={this.state.topStripDisplay}
                    navbarOpen={this.state.mobileNavOpen}
                    toggleCb={this.toggleMobileNavOpen}
                  />
                </div>
              </div>
            </div>
          </Container>
        </Navbar>
        {/*
          Mobile Menu Footer (solves issues with vh on mobile devices)
          */}
        <MobileMenuFooter mobileNavOpen={this.state.mobileNavOpen} clickCb={this.toggleMobileNavOpen} />
      </div>
    );
  }
}
