import { Link } from "gatsby";
import React, { useEffect } from "react";
import { Button, Col, Row } from "reactstrap";
import { CognitoStore, session } from "src/stores/cognito-store";

import { SignOut } from "src/components/Auth/SignOut";
import { NavLinkHelper } from "./NavLinkHelper";

export interface UserMobileProps {
  toggleCb: () => void;
}
export const UserMobile: React.FunctionComponent<UserMobileProps> = (props: UserMobileProps) => {
  let authSub: any;
  const [authorized, setAuthorized] = React.useState(false);
  const [userGroups, setUserGroups] = React.useState(null);
  useEffect(() => {
    // Anything in here is fired on component mount.
    authSub = session.subscribe((sess) => {
      if (sess) {
        setAuthorized(true);
        setUserGroups(sess.groups);
      } else {
        setAuthorized(false);
        setUserGroups(null);
      }
    });

    CognitoStore.userSession()
      .then((session) => {
        setAuthorized(true);
        return setUserGroups(session.groups);
      })
      .catch((error) => {
        // the user isnt logged in
        console.log(error);
      });
    return () => {
      // Anything in here is fired on component unmount.

      if (authSub) {
        authSub.unsubscribe();
      }
    };
  }, []);

  const getNavAction = (): React.ReactNode => {
    if (authorized === false) {
      return <></>;
    }
    const dashboardPath = NavLinkHelper.getDashboardPath(userGroups);
    return (
      <Link to={dashboardPath} onClick={props.toggleCb}>
        <Button color="secondary">Dashboard</Button>
      </Link>
    );
  };

  // eslint-disable-next-line consistent-return
  const getAuthAction = (): any => {
    if (authorized) {
      return (
        <Row>
          <Col xs="6">{getNavAction()}</Col>
          <Col xs="6">
            <Button color="primary">
              <SignOut signoutCb={props.toggleCb} />
            </Button>
          </Col>
        </Row>
      );
    }
  };
  return (
    <div>
      {authorized === false && (
        <Link to="/login/" onClick={props.toggleCb}>
          <Button color="secondary" className="login-btn">
            Log In
          </Button>
        </Link>
      )}
      {authorized === true && <>{getAuthAction()}</>}
    </div>
  );
};
