import React from "react";
import { Row, Col, Container, Button } from "reactstrap";
import { SearchService } from "src/services/search-service";
import { SiteSearch, iSiteSearchResponse } from "ps-shared";
import { SearchComponent } from "src/components/Search/SearchComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faHandPointer } from "@fortawesome/free-solid-svg-icons/faHandPointer";

export interface SearchDrawerProps {
  closeCb: () => void;
}

export const SearchDrawer: React.FunctionComponent<SearchDrawerProps> = (props: SearchDrawerProps) => {
  const [loading, setLoading] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState("");
  const [results, setResults] = React.useState<SiteSearch[]>();
  const [hits, setHits] = React.useState(0);
  const performSearch = (s: string): void => {
    setLoading(true);
    setSearchInput(s);
    SearchService.exploreSite(s, "all", 0, 5)
      .then((r: iSiteSearchResponse) => {
        setResults(r.results);
        setHits(r.hits);
        return setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const clearSearch = (): void => {
    // reset the state
    setResults(null);
    setHits(0);
    setSearchInput("");
  };
  const doNav = (search: SiteSearch): void => {
    window.location.href = `${process.env.PSYSUP_SITE_URL}${search.path}`;
  };
  return (
    <>
      <div className="search-drawer d-lg-block d-none">
        <Container>
          <Row>
            <Col className="text-end">
              <FontAwesomeIcon className="cursor-pointer close mt-5" icon={faTimes} onClick={props.closeCb} />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <SearchComponent
                input={searchInput}
                searchCb={(s: string): void => performSearch(s)}
                loading={loading}
                clearCb={clearSearch}
                location={"drawer"}
              />
              <div className="mt-5 results">
                {results && results.length > 0 && (
                  <Row className="mb-3">
                    {results.map((r, i) => {
                      return (
                        <Col className="mb-3" xs="4" key={i}>
                          <a onClick={() => doNav(r)} className="title">
                            {r.title}
                          </a>
                          <div className="mt-3 desc">{r.desc}</div>
                        </Col>
                      );
                    })}
                  </Row>
                )}
              </div>
              {results && results.length === 0 && (
                <div className="mt-5 no-results">
                  <i>No results matched your search terms</i>
                </div>
              )}
              {hits === 0 && (
                <div>
                  <h4 className="fw400 cta mb-3">Try searching for:</h4>
                  <Button color="primary" onClick={(): void => performSearch("MDMA")}>
                    MDMA
                  </Button>
                  <Button color="primary" onClick={(): void => performSearch("Psilocybin")}>
                    Psilocybin
                  </Button>
                  <Button color="primary" onClick={(): void => performSearch("Ketamine")}>
                    Ketamine
                  </Button>
                </div>
              )}
              {hits > 5 && (
                <div className="mt-5 see-all">
                  <a href={`/search?s=${searchInput}`} onClick={(): void => props.closeCb()}>
                    See all {hits} results
                    <FontAwesomeIcon icon={faHandPointer} className="ms-2" />
                  </a>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
