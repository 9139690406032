import Client, { Cart } from "shopify-buy";
import ls from "src/helpers/localStorage";
import { Observable, BehaviorSubject } from "rxjs";

const lsCheckout = "checkoutid";

const shopifyClient = Client.buildClient({
  storefrontAccessToken: process.env.SHOPIFY_STORE_TOKEN,
  domain: process.env.SHOPIFY_STORE_DOMAIN,
});

const checkoutSubj: BehaviorSubject<Cart> = new BehaviorSubject(null);
const checkout: Observable<Cart> = checkoutSubj.asObservable();
/*
note the create checkout allows email address
*/
/*
note its possible to add shipping address when you get there
checkout.updateShippingAddress(
        this.props.checkout.id,
        {
          address1: 'Chestnut Street 92',
          address2: 'Apartment 2',
          city: 'Louisville',
          company: null,
          country: 'United States',
          firstName: 'Bob',
          lastName: 'Norman',
          phone: '555-625-1199',
          province: 'Kentucky',
          zip: '40202'
        }
      )
*/
class CheckoutStore {
  public addVariant(variantId: string, quantity: number): Promise<Cart> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await this.getCart();
      shopifyClient.checkout
        .addLineItems(checkoutSubj.value.id, [{ variantId, quantity }])
        .then((res) => {
          checkoutSubj.next(res);
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  public updateVariant(id: string, quantity: number): Promise<Cart> {
    return new Promise((resolve, reject) => {
      shopifyClient.checkout
        .updateLineItems(checkoutSubj.value.id, [{ id, quantity }])
        .then((res) => {
          checkoutSubj.next(res);
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  public removeLineItem(lineItemId: string): Promise<Cart> {
    return new Promise((resolve, reject) => {
      shopifyClient.checkout
        .removeLineItems(checkoutSubj.value.id, [lineItemId])
        .then((res) => {
          checkoutSubj.next(res);
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  public getCart(): Promise<Cart> {
    return new Promise((resolve) => {
      const checkoutId = ls.get(lsCheckout);
      if (!checkoutId) {
        shopifyClient.checkout.create().then((r) => {
          ls.save(lsCheckout, r.id as string);
          checkoutSubj.next(r);
          resolve(r);
        });
      } else {
        shopifyClient.checkout.fetch(checkoutId).then((r) => {
          console.log(r);
          // checkout could be null in some cases
          // or checkout has already been converted to order
          if (!r || (r && r.order)) {
            ls.remove(lsCheckout);
            shopifyClient.checkout.create().then((co) => {
              ls.save(lsCheckout, co.id as string);
              checkoutSubj.next(co);
              resolve(r);
            });
          } else {
            this.sanitizeCheckout(r).then((c) => {
              checkoutSubj.next(c);
              resolve(c);
            });
          }
        });
      }
    });
  }

  // handle edge case where an item has become invalid while still in someone's cart
  // https://github.com/Shopify/js-buy-sdk/issues/474#issuecomment-547476169
  private sanitizeCheckout(cart: Cart): Promise<Cart> {
    const lineItemsToDelete = cart.lineItems.filter((item) => !item.variant);
    if (!lineItemsToDelete.length) {
      return Promise.resolve(cart);
    }
    const lineItemIds = lineItemsToDelete.map((item) => item.id);
    return shopifyClient.checkout.removeLineItems(cart.id, lineItemIds as string[]).then((newCheckout) => {
      return newCheckout;
    });
  }
}

export { CheckoutStore, checkout };
