import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { CognitoStore, session } from "src/stores/cognito-store";
import { NavLinkHelper } from "./NavLinkHelper";

export interface UserDesktopProps {}
export const UserDesktop: React.FunctionComponent<UserDesktopProps> = () => {
  let authSub: any;
  const [authorized, setAuthorized] = React.useState(false);
  const [userGroups, setUserGroups] = React.useState(null);
  const [firstName, setFirstName] = React.useState("");
  const [hover, setHover] = React.useState(false);
  useEffect(() => {
    // Anything in here is fired on component mount.
    authSub = session.subscribe((sess) => {
      if (sess) {
        setFirstName(sess.firstName);
        setAuthorized(true);
        setUserGroups(sess.groups);
      } else {
        setAuthorized(false);
        setUserGroups(null);
      }
    });

    CognitoStore.userSession()
      .then((session) => {
        setFirstName(session.firstName);
        setAuthorized(true);
        return setUserGroups(session.groups);
      })
      .catch((error) => {
        // the user isnt logged in
        console.log(error);
      });
    return () => {
      // Anything in here is fired on component unmount.

      if (authSub) {
        authSub.unsubscribe();
      }
    };
  }, []);
  const signOut = (): void => {
    CognitoStore.logout()
      .then(() => {
        return setAuthorized(false);
      })
      .catch((error: any) => {
        console.error("error signing out");
        console.error(error);
      });
  };

  const getNavAction = (): React.ReactNode => {
    if (authorized === false) {
      return <></>;
    }
    const dashboardPath = NavLinkHelper.getDashboardPath(userGroups);
    return (
      <Link to={dashboardPath}>
        <Button color="secondary">Dashboard</Button>
      </Link>
    );
  };

  // eslint-disable-next-line consistent-return
  const getAuthAction = (): any => {
    if (authorized) {
      return (
        <>
          <div
            className="cursor-pointer intro"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <a>Hi, {firstName}</a>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
          {hover === true && (
            <div
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              className="user-flyout d-flex flex-column align-items-center"
            >
              <div>{getNavAction()}</div>
              <div className="cursor-pointer sign-out mt-2" onClick={signOut}>
                Sign Out
              </div>
            </div>
          )}
        </>
      );
    }
  };
  return (
    <div>
      {authorized === false && (
        <Link to="/login/">
          <Button color="secondary" className="login-btn">
            Log In
          </Button>
        </Link>
      )}
      {authorized === true && <>{getAuthAction()}</>}
    </div>
  );
};
