/*eslint-disable*/
import React from 'react';
import { SignInFooter } from 'src/components/Footers/SignIn';
// reactstrap components
import { Row, Container, Col } from 'reactstrap';
import { Link } from 'gatsby';
import { SubscribeInterests } from 'src/components/Email/SubscribeInterests';
import linkedIn from 'src/assets/img/linkedin.svg';
import facebook from 'src/assets/img/facebook.svg';
import instagram from 'src/assets/img/instagram.svg';
import youtube from 'src/assets/img/youtube.svg';
interface IndexFooterProps {
  footerBorderless: boolean;
}
interface IndexFooterState {}

export default class IndexFooter extends React.Component<IndexFooterProps, IndexFooterState> {
  constructor(props: IndexFooterProps) {
    super(props);
  }

  public render() {
    return (
      <>
        <footer className={`footer footer-nav ${!this.props.footerBorderless ? 'mt-5' : ''}`}>
          <Container>
            <Row className="mt-4">
              <Col xs="12" md="3" className="mt-2">
                <Row>
                  <Col md="12" xs="6" className="title">
                    Us
                  </Col>
                  <Col md="12" xs="6">
                    <Link to="/our-story/">Our Story</Link>
                  </Col>
                  {/*
               <Col md={{ size: '12', offset: 0 }} xs={{ size: '6', offset: 6 }}>
              <Link to="/press-release/">
                  Press Releases
                  </Link>
              </Col>
              */}
                  <Col md={{ size: '12', offset: 0 }} xs={{ size: '6', offset: 6 }}>
                    <a href="mailto:info@psychedelic.support">Contact</a>
                  </Col>
                  <Col md={{ size: '12', offset: 0 }} xs={{ size: '6', offset: 6 }} className="social">
                    <a href="https://www.linkedin.com/company/psychedelic-support" title="Psychedelic Support on LinkedIn" target="_blank">
                      <img src={linkedIn} className="linkedin" alt="LinkedIn Icon" title="Psychedelic Support on LinkedIn" />
                    </a>
                    <a href="https://www.facebook.com/PsychedelicSupportNetwork" title="Psychedelic Support on Facebook" target="_blank">
                      <img src={facebook} className="facebook" alt="Facebook Icon" title="Psychedelic Support on Facebook" />
                    </a>
                    <a href="https://www.instagram.com/psychedelicsupport/" title="Psychedelic Support on Instagram" target="_blank">
                      <img src={instagram} className="instagram" alt="Instagram Icon" title="Psychedelic Support on Instagram" />
                    </a>
                    <a
                      href="https://www.youtube.com/@psychedelicsupport"
                      title="Psychedelic Support on Youtube"
                      target="_blank"
                    >
                      <img src={youtube} className="youtube" alt="Youtube Icon" title="Psychedelic Support on Youtube" />
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col xs="12" md="3" className="mt-2">
                <Row>
                  <Col xs="6" md="12" className="title">
                    You
                  </Col>
                  <Col md="12" xs="6">
                    <Link to="/join/">Join our Network</Link>
                  </Col>
                  <Col md="12" xs="6">
                    <Link to="/professional-connect/">Professional Connect</Link>
                  </Col>
                  <Col md={{ size: '12', offset: 0 }} xs={{ size: '6', offset: 6 }}>
                    <a href="https://psychedelicsupport.talentlms.com" target="_blank" rel="noreferrer nofollow noopener">
                      Course Login
                    </a>
                  </Col>
                  <Col md={{ size: '12', offset: 0 }} xs={{ size: '6', offset: 6 }}>
                    <SignInFooter />
                  </Col>
                </Row>
              </Col>
              <Col xs="12" md="3" className="mt-2">
                <Row>
                  <Col xs="6" md="12" className="title">
                    More Info
                  </Col>
                  <Col md="12" xs="6">
                    <Link to="/resources/how-to-join-psychedelic-clinical-trial/">Join a Clinical Trial</Link>
                  </Col>
                  <Col md={{ size: '12', offset: 0 }} xs={{ size: '6', offset: 6 }}>
                    <Link to="/education/">Courses & Trainings</Link>
                  </Col>
                  <Col md={{ size: '12', offset: 0 }} xs={{ size: '6', offset: 6 }}>
                    <Link to="/psychedelic-education-scholarship/">Diversity Scholarship</Link>
                  </Col>
                  <Col md={{ size: '12', offset: 0 }} xs={{ size: '6', offset: 6 }}>
                    <Link to="/education/free-courses/">Free Courses</Link>
                  </Col>
                  <Col md={{ size: '12', offset: 0 }} xs={{ size: '6', offset: 6 }}>
                    <Link to="/faq/">FAQ</Link>
                  </Col>
                  <Col md={{ size: '12', offset: 0 }} xs={{ size: '6', offset: 6 }}>
                    <Link to="/terms-of-service/">Terms of Service</Link>
                  </Col>
                  <Col md={{ size: '12', offset: 0 }} xs={{ size: '6', offset: 6 }}>
                    <Link to="/equity-statement/">Equity Statement</Link>
                  </Col>
                </Row>
              </Col>
              <Col xs="12" md="3" className="mt-2 subscribe">
                <div className="title">Subscribe for News:</div>
                <SubscribeInterests btnClass="btrans" />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col sm="12" className="terms">
                Psychedelic Support and the providers listed here do not endorse the use of illegal substances. We recognize the necessity
                for empathetic care and harm reduction strategies. Providers do not break U.S. laws. Do not ask for illegal substances or
                referrals to underground practitioners.
                <br />
                <div className="copyright mt-2">
                  © {new Date().getFullYear()}&nbsp;
                  <svg xmlns="http://www.w3.org/2000/svg" className="heart" viewBox="0 -256 1850 1850">
                    <g transform="matrix(1,0,0,-1,37.966102,1343.4237)">
                      <path d="m 896,-128 q -26,0 -44,18 L 228,492 q -10,8 -27.5,26 Q 183,536 145,583.5 107,631 77,681 47,731 23.5,802 0,873 0,940 q 0,220 127,344 127,124 351,124 62,0 126.5,-21.5 64.5,-21.5 120,-58 55.5,-36.5 95.5,-68.5 40,-32 76,-68 36,36 76,68 40,32 95.5,68.5 55.5,36.5 120,58 64.5,21.5 126.5,21.5 224,0 351,-124 127,-124 127,-344 0,-221 -229,-450 L 940,-110 q -18,-18 -44,-18" />
                    </g>
                  </svg>
                  &nbsp;Psychedelic Support Inc, All rights reserved&nbsp;&nbsp;·&nbsp;<Link to="/sitemap/">Sitemap</Link>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}
