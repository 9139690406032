import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

import { v4 as uuidv4 } from "uuid";
import Helpers from "src/services/helpers";
/* auth */
const poolData = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ClientId: process.env.PSYSUP_CLIENTID ?? "",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  UserPoolId: process.env.PSYSUP_USERPOOL ?? "",
};
const userpool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

// eslint-disable-next-line import/prefer-default-export
export class CognitoService {
  public static logout(): Promise<any> {
    return new Promise((resolve) => {
      const user = userpool.getCurrentUser();
      if (user) {
        user.signOut();
      }
      return resolve(null);
      // eslint-disable-next-line prefer-promise-reject-errors
    });
  }

  public static authenticateUser(userName: string, password: string, newPassword: string = null): Promise<any> {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getCognitoUser(userName);
      const authenticationData = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Password: password,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Username: userName.toLowerCase(),
      };
      const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
      cognitoUser.authenticateUser(authenticationDetails, {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        newPasswordRequired: (userAttributes: any, requiredAttributes: any) => {
          if (newPassword) {
            cognitoUser.completeNewPasswordChallenge(newPassword, undefined, {
              onSuccess: (result: any) => {
                resolve({
                  token: result,
                  user: result.idToken.payload,
                });
              },
              onFailure: (err: any) => {
                reject(err);
              },
            });
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({ code: "set-password" });
          }
        },
        onFailure: (err: any) => {
          reject(err);
        },
        onSuccess: (session: any) => {
          // save session
          resolve({
            token: session,
            user: session.idToken.payload,
          });
        },
      });
    });
  }

  public static signupUser(email: string, password: string, firstName: string, lastName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      userpool.signUp(
        uuidv4(),
        password, // user sets their own pw after email verification
        // eslint-disable-next-line @typescript-eslint/naming-convention
        [
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { Name: "email", Value: email.toLowerCase() } as any,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { Name: "given_name", Value: Helpers.capFirst(firstName) } as any,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { Name: "family_name", Value: Helpers.capFirst(lastName) } as any,
        ],
        null,
        (err, result) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(result.user);
        },
      );
    });
  }

  private static getCognitoUser(email: string): any {
    const user = new AmazonCognitoIdentity.CognitoUser({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Username: email.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Pool: userpool,
    });
    user.setAuthenticationFlowType("USER_PASSWORD_AUTH");
    return user;
  }

  public static forgotPassword(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getCognitoUser(email);
      cognitoUser.forgotPassword({
        onSuccess: () => {
          resolve();
        },
        onFailure: (err: any) => {
          reject(err);
        },
      });
    });
  }

  public static confirmPassword(email: string, code: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getCognitoUser(email);
      cognitoUser.confirmPassword(code, password, {
        onSuccess: () => {
          resolve();
        },
        onFailure: (err: any) => {
          reject(err);
        },
      });
    });
  }

  public static confirmRegistration(email: string, code: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getCognitoUser(email);
      cognitoUser.confirmRegistration(code, false, (err: any, data: any) => {
        if (err) {
          return reject(err);
        }
        return resolve(data);
      });
    });
  }

  public static refreshSession(): Promise<any> {
    return new Promise((resolve, reject) => {
      const user = userpool.getCurrentUser();
      if (user) {
        user.getSession((err: any, session: any) => {
          if (err) {
            console.error("unable to get user session");
            reject(new Error("unable to get user session"));
          }
          user.refreshSession(session.refreshToken, (error: any, theSession: any) => {
            if (error) {
              reject(new Error("unable to refresh token"));
            } else {
              resolve(theSession);
            }
          });
        });
      } else {
        console.error("no user found, cannot refresh session");
        reject(new Error("unable to refresh token, no user found"));
      }
    });
  }

  public static userSession(): Promise<any> {
    // check for cognito
    return new Promise((resolve, reject) => {
      const user = userpool.getCurrentUser();
      if (!user) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return reject("expired_session");
      }
      return user.getSession((err: any, session: any) => {
        if (err) {
          console.error("no session");
          // eslint-disable-next-line prefer-promise-reject-errors
          return reject("no_session");
        }
        if (session.isValid()) {
          return resolve({
            token: session,
            user: session.idToken.payload,
          });
        }
        console.error("expired session");
        // eslint-disable-next-line prefer-promise-reject-errors
        return reject("expired_session");
      });
    });
  }
}
