import React, { ReactNode } from "react";
import { SearchDrawer } from "../Search/SearchDrawer";
import { TopNav } from "./TopNav";

interface SearchTopNavProps {
  stickyNav: boolean;
}
interface SearchTopNavState {
  searchOpen: boolean;
}
export class SearchTopNav extends React.Component<SearchTopNavProps, SearchTopNavState> {
  constructor(props: SearchTopNavProps) {
    super(props);
    this.state = {
      searchOpen: false,
    };
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  public toggleSearch = (): void => {
    if (this.state.searchOpen === false) {
      this.setState({
        searchOpen: true,
      });
    } else {
      this.setState({
        searchOpen: false,
      });
    }
  };

  // tslint:disable:max-line-length
  public render(): ReactNode {
    return (
      <>
        {this.state.searchOpen && <SearchDrawer closeCb={this.toggleSearch} />}
        <TopNav stickyNav={this.props.stickyNav} toggleSearchCb={this.toggleSearch} />
      </>
    );
  }
}
