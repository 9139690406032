
import "src/assets/scss/paper-kit.scss";
import "src/assets/css/ReactCrop.css";
// This ensures that the icon CSS is loaded immediately before attempting to render icons
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";
import ReactGA from "react-ga"

import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false;

let gaIsInitialized = false;


const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.PSYSUP_RECAPTCHA}
        scriptProps={{
          async: true, // optional, default to false,
          defer: true }}>
            {element}
        </GoogleReCaptchaProvider>
    )
}


const onClientEntry = (_, pluginOptions = {}) => {
  // google analytics
  if (!gaIsInitialized) {
    initReactGA();
  }
}
const initReactGA = () => {
  ReactGA.initialize(process.env.UAID, {
    titleCase: false,
    gaOptions: {
      sampleRate: 100,
      siteSpeedSampleRate: 100,
      //debug: true
    }
  });
  // enhanced ecommerce
  ReactGA.plugin.require('ec');
  gaIsInitialized = true;
}

const onRouteUpdate = ({ location }, pluginOptions = {}) => {
    // google analytics
    //if ( cookies.get(process.env.GA_GDPR_COOKIE) === `true`) {
      if (!gaIsInitialized) {
        initReactGA();
      }
      const gaAnonymize = false;
      ReactGA.set({ page: location.pathname, anonymizeIp: gaAnonymize })
      ReactGA.pageview(location.pathname)
    //}
}
export { onClientEntry, onRouteUpdate, wrapRootElement}
