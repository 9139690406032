import React, { useEffect } from "react";
import { Navbar, NavItem } from "reactstrap";
import { SearchNavMobile } from "src/components/Search/SearchNavMobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons/faLongArrowAltLeft";
import { Link } from "gatsby";
import menus from "./menu.json";
import { Cart } from "../Cart";

export interface MobileMenuProps {
  topStripDisplay: boolean;
  toggleCb: () => void;
  navbarOpen: boolean;
}

enum DisplayMode {
  Menu = "Menu",
  Search = "Search",
}

export const MobileMenu: React.FunctionComponent<MobileMenuProps> = (props: MobileMenuProps) => {
  const [mode, setMode] = React.useState(DisplayMode.Menu);
  const [scrollableHeight, setScrollableHeight] = React.useState("");
  const [topMenu, setTopMenu] = React.useState();
  // **these are also css variables!
  const mobileMenuFooterHeight = 50;
  const topStripHeight = 50;
  const navHeight = 70;
  const updateScrollableHeight = (): void => {
    if (typeof window !== "undefined") {
      setScrollableHeight(
        `${window.innerHeight - (mobileMenuFooterHeight + (props.topStripDisplay ? topStripHeight : 0) + navHeight)}px`,
      );
    }
  };
  useEffect(() => {
    updateScrollableHeight();
    if (props.navbarOpen === true) {
      document.querySelectorAll("html")[0].style.overflow = "hidden";
    } else {
      // reset the state
      setMode(DisplayMode.Menu);
      // eslint-disable-next-line unicorn/no-useless-undefined
      setTopMenu(undefined);
      document.querySelectorAll("html")[0].style.overflow = "";
    }
    // Anything in here is fired on component mount.
    return () => {
      // Anything in here is fired on component unmount.
    };
  });

  const toggleNavbarCollapse = (): void => {
    props.toggleCb();
  };
  const toggleSearch = (): void => {
    setMode(DisplayMode.Search);
  };
  const toggleMenu = (): void => {
    setMode(DisplayMode.Menu);
  };
  const toggleSubMenu = (menu): void => {
    setTopMenu(menu);
  };

  return (
    <>
      <div className={"mobile-nav"}>
        <div id="tour-navbar-mobile">
          <button
            aria-expanded={props.navbarOpen}
            className={`navbar-toggler navbar-toggler ${props.navbarOpen ? "mobile-close" : ""}`}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        {props.navbarOpen === true && (
          <div className={"d-flex flex-column"}>
            <Navbar
              className={`fixed-top d-flex flex-row justify-content-end mobile-nav ${
                props.topStripDisplay ? "top-strip-open" : "top-strip-closed"
              }`}
            >
              <div className="menu d-flex flex-column justify-content-between">
                <div className="inner">
                  <div className="control">
                    <div>
                      {mode === DisplayMode.Menu && topMenu === undefined && (
                        <FontAwesomeIcon className="cursor-pointer" icon={faSearch} onClick={toggleSearch} />
                      )}
                      {mode === DisplayMode.Search && (
                        <a className="cursor-pointer" onClick={toggleMenu} style={{ fontSize: ".8em" }}>
                          <FontAwesomeIcon icon={faLongArrowAltLeft} />
                          &nbsp;Main Menu
                        </a>
                      )}
                      {mode === DisplayMode.Menu && topMenu !== undefined && (
                        <a
                          className="cursor-pointer"
                          style={{ fontSize: ".8em" }}
                          // eslint-disable-next-line unicorn/no-useless-undefined
                          onClick={(): void => toggleSubMenu(undefined)}
                        >
                          <FontAwesomeIcon icon={faLongArrowAltLeft} />
                          &nbsp; Main Menu
                        </a>
                      )}
                    </div>
                    <div id="tour-navbar-mobile">
                      <button
                        aria-expanded={props.navbarOpen}
                        className={`navbar-toggler navbar-toggler ${props.navbarOpen ? "mobile-close" : ""}`}
                        onClick={toggleNavbarCollapse}
                      >
                        <span className="navbar-toggler-bar bar1" />
                        <span className="navbar-toggler-bar bar2" />
                        <span className="navbar-toggler-bar bar3" />
                      </button>
                    </div>
                  </div>
                  {mode === DisplayMode.Menu && (
                    <div style={{ height: scrollableHeight, overflowY: "scroll" }}>
                      <Cart toggleCb={toggleNavbarCollapse} calcCb={updateScrollableHeight} />
                      {/*
                  First Level Menu
                  */}
                      {topMenu === undefined && (
                        <ul className="navbar-nav">
                          {menus.map((m, i) => {
                            return (
                              <NavItem key={i} onClick={(): void => toggleSubMenu(m)} className="cursor-pointer">
                                <div className="d-flex flex-row justify-content-between">
                                  <div>
                                    <a>{m.title}</a>
                                  </div>
                                  <div className="chev-right">
                                    <FontAwesomeIcon icon={faChevronRight} />
                                  </div>
                                </div>
                              </NavItem>
                            );
                          })}
                        </ul>
                      )}

                      {/*
              Second Level Menu
              */}
                      {topMenu && (
                        <ul className="navbar-nav">
                          {topMenu.sub.map((m, i) => {
                            if (m.sub) {
                              return (
                                <NavItem key={i}>
                                  <div className="fw500">
                                    <Link to={m.link} onClick={toggleNavbarCollapse}>
                                      {m.title}
                                    </Link>
                                  </div>
                                  {m.sub.map((ms, index: number) => {
                                    return (
                                      <div className="sub" key={index}>
                                        <Link to={ms.link} onClick={toggleNavbarCollapse}>
                                          {ms.title}
                                        </Link>
                                      </div>
                                    );
                                  })}
                                </NavItem>
                              );
                            }
                            return (
                              <NavItem key={i}>
                                <Link onClick={toggleNavbarCollapse} to={m.link}>
                                  {m.title}
                                </Link>
                                {m.body && <p>{m.body}</p>}
                              </NavItem>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  )}
                  {mode === DisplayMode.Search && <SearchNavMobile doneCb={toggleNavbarCollapse} />}
                </div>
              </div>
            </Navbar>
          </div>
        )}
      </div>
    </>
  );
};
