export class NavLinkHelper {
  /* super admin functions */
  public static getDashboardPath(userGroups: string[]): string {
    if (userGroups && (userGroups.includes("provider") || userGroups.includes("community"))) {
      return "/my/profiles/";
    }
    if (userGroups && userGroups.includes("super")) {
      return "/admin/";
    }
    return "/my/journey/";
  }
}
