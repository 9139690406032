import { Observable, BehaviorSubject } from "rxjs";

const navHeighSubject: BehaviorSubject<number> = new BehaviorSubject(null);
const navHeight: Observable<number> = navHeighSubject.asObservable();

class PresentationStore {
  public static setNavHeight(val: number): void {
    navHeighSubject.next(val);
  }
}

export { PresentationStore, navHeight };
