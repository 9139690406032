import React from "react";
import { PublicService } from "src/services/PublicService";
import { Button, Col, Form, FormGroup, Row, Label, Input } from "reactstrap";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export enum SubscriptionInterests {
  FreeCoures = "5a11f2c1cd",
  General = "d2e21102f7",
}
interface SubscribeInterestsProps {
  interests?: string[];
  btnClass?: string;
  btnText?: string;
  includeName?: boolean;
  successCb?: () => void;
}

// eslint-disable-next-line react/display-name
export const SubscribeInterests: React.FunctionComponent<SubscribeInterestsProps> = React.memo(
  (props: SubscribeInterestsProps) => {
    // form actions
    const [submitted, setSubmitted] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [errored, setErrored] = React.useState(false);
    const [formErrors, setFormErrors] = React.useState(null);

    // form fields
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");

    const { executeRecaptcha } = useGoogleReCaptcha();

    const checkFormErrors = (name: string, value: string) => {
      let error;
      switch (name) {
        case "firstName":
          error = value && value.length ? "" : "First Name is Required";
          break;
        case "lastName":
          error = value && value.length ? "" : "Last Name is Required";
          break;
        case "email":
          error = value && /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,10}$/i.test(value) ? "" : "Email is not valid!";
          break;
        default:
          break;
      }
      return error;
    };
    const validateForm = () => {
      let valid = true;
      const errors = {} as any;
      errors.email = checkFormErrors("email", email);
      if (props.includeName) {
        errors.firstName = checkFormErrors("firstName", firstName);
        errors.lastName = checkFormErrors("lastName", lastName);
      }
      Object.keys(errors).forEach(
        // if we have an error string set valid to false
        (p: any) => {
          if (errors[p] && errors[p].length > 0) {
            valid = false;
          }
        },
      );
      return { valid, errors };
    };

    const handleSubmit = async (event: any) => {
      event.preventDefault();
      try {
        setSubmitting(true);
        setErrored(false);
        const myForm = validateForm();
        if (!myForm.valid) {
          setSubmitting(false);
          setFormErrors(myForm.errors);
          return;
        }
        const token = await executeRecaptcha("subscribeInterests");
        const values = {
          firstName,
          lastName,
          email,
          interests: props.interests && props.interests.length ? props.interests : [SubscriptionInterests.General],
        };
        await PublicService.subscribeEmailMarketing(values, token);
        if (props.successCb) {
          props.successCb();
        }
        setSubmitted(true);
      } catch (error) {
        setErrored(true);
        console.error(error);
        setSubmitting(false);
      }
    };
    // tslint:disable:max-line-length
    return (
      <div className="subscribe-interests">
        {!submitted && (
          <div>
            <Row>
              <Col xs="12">
                <Form role="form" className="subscribe-interests" onSubmit={handleSubmit}>
                  {props.includeName === true && (
                    <Row>
                      <Col xs="6">
                        <FormGroup className="mb-3">
                          <Label for="name" id="lbl-firstName">
                            First Name:
                          </Label>
                          <br />
                          <Input
                            type="text"
                            autoComplete="given-name"
                            value={firstName}
                            name="firstName"
                            placeholder="First Name"
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                          {formErrors && formErrors.firstName && (
                            <div className="has-error">
                              <div className="form-feedback">{formErrors.firstName}</div>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup className="mb-3">
                          <Label for="name" id="lbl-lastName">
                            Last Name:
                          </Label>
                          <br />
                          <Input
                            type="text"
                            autoComplete="family-name"
                            value={lastName}
                            name="lastName"
                            placeholder="Last Name"
                            onChange={(e) => setLastName(e.target.value)}
                          />
                          {formErrors && formErrors.lastName && (
                            <div className="has-error">
                              <div className="form-feedback">{formErrors.lastName}</div>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col xs="12">
                      <FormGroup className="mb-3">
                        <Label for="name" id="lbl-email">
                          Email:
                        </Label>
                        <Input
                          type="text"
                          autoComplete="email"
                          name="email"
                          value={email}
                          placeholder="Email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {formErrors && formErrors.email && (
                          <div className="has-error">
                            <div className="form-feedback">{formErrors.email}</div>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" className="text-center">
                      <Button
                        id="btn-submit"
                        color={`${props.btnClass ? props.btnClass : "secondary"}`}
                        type="submit"
                        disabled={submitting}
                      >
                        {props.btnText ? props.btnText : "Sign Up"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                {errored && (
                  <FormGroup>
                    <div className="form-feedback">There was an error submitting your request, please try again!</div>
                  </FormGroup>
                )}
              </Col>
            </Row>
          </div>
        )}
        {!props.successCb && submitted && <div className="text-center">Got it! Check your inbox :)</div>}
      </div>
    );
  },
);
