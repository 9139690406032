import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
// import {Draggable} from "./Draggable";
export interface HubspotChatProps {
  display?: boolean;
}

export const HubspotChat: React.FunctionComponent<HubspotChatProps> = (props: HubspotChatProps) => {
  const [didLoad, setDidLoad] = React.useState(false);

  useEffect(() => {
    if (typeof window === "undefined") {
      // we are ssr
      return;
    }
    (window as any).hsConversationsSettings = {
      loadImmediately: false,
    };
    (window as any).hsConversationsOnReady = [
      () => {
        setDidLoad(true);
        handleHubspot();
      },
    ];
    if (didLoad) {
      handleHubspot();
    }
  });
  const handleHubspot = () => {
    if (props.display === false) {
      if ((window as any).HubSpotConversations && (window as any).HubSpotConversations.widget) {
        // if the hubspot is already loaded
        (window as any).HubSpotConversations.widget.remove();
      }
    } else if (props.display === true || props.display === undefined) {
      if ((window as any).HubSpotConversations && (window as any).HubSpotConversations.widget) {
        // load the widget
        (window as any).HubSpotConversations.widget.load();
      }
    }
  };

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`
          window.hsConversationsSettings = {
            loadImmediately: false
            };
          `}
        </script>
        <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/20139425.js" />
      </Helmet>
      <div id="hs-ch">
        <div id="ps-hs-chat"></div>
      </div>
    </>
  );
};
