import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
import { Link } from "gatsby";
// reactstrap components
import { Collapse, Container, Nav, NavItem, Navbar } from "reactstrap";

interface AdminNavbarProps {
  transparentNav: boolean;
  stickyNav: boolean;
}
interface AdminNavbarState {
  authorized: boolean;
  modal: boolean;
  navbarCollapse: boolean;
  navbarColor: string;
  userType: string;
}
export default class AdminNavbar extends React.Component<AdminNavbarProps, AdminNavbarState> {
  constructor(props: AdminNavbarProps) {
    super(props);
    this.toggleNavbarCollapse = this.toggleNavbarCollapse.bind(this);
    this.state = {
      authorized: false,
      modal: false,
      navbarCollapse: false,
      navbarColor: this.getNavbarColor(this.props.transparentNav),
      userType: undefined,
    };
    this.updateNavbarColor = this.updateNavbarColor.bind(this);
  }

  public getNavbarColor(isTransparentNav: boolean) {
    return isTransparentNav ? "navbar-transparent" : "solid";
  }

  public componentDidMount() {
    window.addEventListener("scroll", this.updateNavbarColor);
    if (this.state.navbarCollapse) {
      this.toggleNavbarCollapse();
    }
  }

  public componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("scroll", this.updateNavbarColor);
    }
  }

  public componentDidUpdate(prevProps: AdminNavbarProps) {
    if (this.props.transparentNav !== prevProps.transparentNav || this.props.stickyNav !== prevProps.stickyNav) {
      this.setState({
        navbarColor: this.getNavbarColor(this.props.transparentNav),
      });
    }
  }

  public updateNavbarColor() {
    if (this.props.transparentNav !== true) {
      return;
    }
    if (document.documentElement.scrollTop > 299 || document.body.scrollTop > 299) {
      this.setState({
        navbarColor: "",
      });
    } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      this.setState({
        navbarColor: "navbar-transparent",
      });
    }
  }

  public toggleNavbarCollapse = () => {
    this.setState({
      navbarCollapse: !this.state.navbarCollapse,
    });
    // document.documentElement.classList.toggle("nav-open");
  };

  // tslint:disable:max-line-length
  public render() {
    return (
      <>
        <Navbar expand="lg" className={classnames("sticky-top", this.state.navbarColor)}>
          <Container>
            <div className="navbar-translate">
              <Link to="/">PSv2</Link>
              <button
                aria-expanded={this.state.navbarCollapse}
                className={classnames("navbar-toggler navbar-toggler", {
                  toggled: this.state.navbarCollapse,
                })}
                onClick={this.toggleNavbarCollapse}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <Collapse className="justify-content-end" navbar={true} isOpen={this.state.navbarCollapse}>
              <Nav navbar={true}>
                <NavItem>
                  <Link to="/admin/users" onClick={this.toggleNavbarCollapse}>
                    Search User
                  </Link>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}
