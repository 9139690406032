import axios from "axios";
// import {webContactFormSchema} from "ps-shared";
// import {joinContactFormSchema} from "ps-shared";
import { ExploreQs, iSiteSearchResponse, iExploreResult } from "ps-shared";
// import {subscribeEmailFormSchema} from "ps-shared";
const api = process.env.PSYSUP_API;

// eslint-disable-next-line import/prefer-default-export
export class SearchService {
  public static exploreProfiles(exploreQs: ExploreQs): Promise<iExploreResult> {
    return new Promise((resolve, reject) => {
      // dispatch({ type: EXPLOREPROFILES.REQUEST, scope: "EXPLOREPROFILES" });
      const qsArr: string[] = [];
      // eslint-disable-next-line no-restricted-syntax
      Object.keys(exploreQs).forEach((prop) => {
        if (exploreQs[prop] !== undefined && exploreQs[prop] !== null) {
          // sometimes qs values are arrays, and in this case api gateway is
          // supporting multi value query string parameters
          if (Array.isArray(exploreQs[prop])) {
            (exploreQs[prop] as string[]).forEach((val) => {
              qsArr.push(`${prop}=${val}`);
            });
          } else {
            // otherwise the qs val object is a singleton
            qsArr.push(`${prop}=${exploreQs[prop]}`);
          }
        }
      });
      axios
        .get(`${api}/search/explore/filter/${qsArr.length > 0 ? `?${qsArr.join("&")}` : ""}`)
        .then((response: any) => {
          return resolve(response.data as iExploreResult);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  public static exploreSite(s: string, category: string, start: number, size: number, highlight = false): Promise<iSiteSearchResponse> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${api}/search/site/?s=${s}&start=${start}&category=${category}&size=${size}&highlight=${highlight}`)
        .then((response: any) => {
          return resolve(response.data as iSiteSearchResponse);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }
}
