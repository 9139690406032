import React, { useEffect } from "react";
import { Link } from "gatsby";
import { CognitoStore, session } from "src/stores/cognito-store";
import { Row, Col } from "reactstrap";

export interface SignInFooterProps {}

export const SignInFooter: React.FunctionComponent<SignInFooterProps> = () => {
  const [authorized, setAuthorized] = React.useState(false);
  const [userGroups, setUserGroups] = React.useState(null);
  let authSub: any;
  useEffect(() => {
    // Anything in here is fired on component mount.
    CognitoStore.userSession()
      .then((session) => {
        setAuthorized(true);
        setUserGroups(session.groups);
      })
      .catch((error) => {
        // the user isnt logged in
        console.log(error);
      });
    authSub = session.subscribe((s) => {
      if (s) {
        setAuthorized(true);
        setUserGroups(s.groups);
      } else {
        setAuthorized(false);
        setUserGroups(null);
      }
    });
    return () => {
      // Anything in here is fired on component unmount.
      if (authSub) {
        authSub.unsubscribe();
      }
    };
  }, []);

  const getAuthAction = () => {
    if (authorized) {
      return (
        <>
          {userGroups && (userGroups.includes("provider") || userGroups.includes("community")) && (
            <>
              <Link to="/my/profiles/"> My Profiles </Link>
            </>
          )}
          {userGroups && userGroups.includes("super") && (
            <>
              <Link to="/admin/"> Admin </Link>
            </>
          )}
        </>
      );
    }
    return (
      <>
        <Link to="/login/">Sign In</Link> / <Link to="/login/">Sign Up</Link>
      </>
    );
  };
  return (
    <Row>
      <Col xs="12">{getAuthAction()}</Col>
    </Row>
  );
};
