import React from "react";
import { Link } from "gatsby";
import menus from "./menu.json";

export interface VisMenuProps {}

export const VisMenu: React.FunctionComponent<VisMenuProps> = () => {
  return (
    <div className={"visibility-hidden"}>
      {menus.map((m, i) => {
        return (
          <div key={i}>
            {m.title}
            {m.sub.map((ms, index: number) => {
              return (
                <Link key={index} to={ms.link}>
                  {ms.title}
                </Link>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
