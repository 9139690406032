import React, { useEffect } from "react";
import { CognitoStore, session } from "src/stores/cognito-store";

export interface SignOutProps {
  signoutCb: () => void;
}
export const SignOut: React.FunctionComponent<SignOutProps> = (props: SignOutProps) => {
  let authSub: any;
  const [authorized, setAuthorized] = React.useState(false);

  useEffect(() => {
    // Anything in here is fired on component mount.
    authSub = session.subscribe((sess) => {
      if (sess) {
        setAuthorized(true);
      } else {
        setAuthorized(false);
      }
    });

    CognitoStore.userSession()
      .then(() => {
        return setAuthorized(true);
      })
      .catch((error) => {
        // the user isnt logged in
        console.log(error);
      });
    return () => {
      // Anything in here is fired on component unmount.
      if (authSub) {
        authSub.unsubscribe();
      }
    };
  }, []);
  const signOut = () => {
    CognitoStore.logout()
      .then(() => {
        setAuthorized(false);
        return props.signoutCb();
      })
      .catch((error: any) => {
        console.error("error signing out");
        console.error(error);
      });
  };

  const getAuthAction = (): any => {
    if (authorized) {
      return (
        <div className="sign-out">
          <a onClick={signOut} className="cursor-pointer">
            Sign Out
          </a>
        </div>
      );
    }
    return null;
  };
  return <>{authorized === true && <>{getAuthAction()}</>}</>;
};
