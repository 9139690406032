import { CognitoSession } from "src/models/CognitoSession";
import { Observable, BehaviorSubject } from "rxjs";
import { CognitoService } from "src/services/cognito-service";

// eslint-disable-next-line @typescript-eslint/naming-convention
const sessionSubject: BehaviorSubject<CognitoSession> = new BehaviorSubject(null);
const session: Observable<CognitoSession> = sessionSubject.asObservable();

class CognitoStore {
  public static logout(): Promise<any> {
    return new Promise((resolve, reject) => {
      CognitoService.logout()
        .then(() => {
          sessionSubject.next(null);
          return resolve(null);
        })
        .finally(() => {
          sessionSubject.next(null);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static authenticateUser(userName: string, password: string, newPassword: string = null): Promise<any> {
    return new Promise((resolve, reject) => {
      CognitoService.authenticateUser(userName, password, newPassword)
        .then((s: any) => {
          const currentSess = this.parseCognitoUser(s);
          sessionSubject.next(currentSess);
          return resolve(currentSess);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static signupUser(email: string, password: string, firstName: string, lastName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      CognitoService.signupUser(email, password, firstName, lastName)
        .then((s: any) => {
          return resolve(s);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private static parseCognitoUser(s: any): CognitoSession {
    const sess = {} as CognitoSession;
    sess.email = s.user.email;
    sess.firstName = s.user.given_name;
    sess.lastName = s.user.family_name;
    sess.groups = s.user["cognito:groups"] ? s.user["cognito:groups"] : [];
    sess.username = s.user["cognito:username"];
    return sess;
  }

  private static parseCognitoSession(s: any): CognitoSession {
    const sess = {} as CognitoSession;
    sess.email = s.idToken.payload.email;
    sess.firstName = s.idToken.payload.given_name;
    sess.lastName = s.idToken.payload.family_name;
    sess.groups = s.idToken.payload["cognito:groups"] ? s.idToken.payload["cognito:groups"] : [];
    sess.username = s.idToken.payload["cognito:username"];
    return sess;
  }

  public static forgotPassword(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      CognitoService.forgotPassword(email)
        .then((s) => {
          return resolve(s);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static confirmPassword(email: string, code: string, password: string): Promise<CognitoSession> {
    return new Promise((resolve, reject) => {
      CognitoService.confirmPassword(email, code, password)
        // eslint-disable-next-line promise/always-return
        .then(() => {
          // eslint-disable-next-line promise/no-nesting
          CognitoService.authenticateUser(email, password)
            .then((s) => {
              const currentSess = this.parseCognitoUser(s);
              sessionSubject.next(currentSess);
              return resolve(currentSess);
            })
            .catch((error) => {
              return reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static confirmRegistration(username: string, code: string): Promise<any> {
    return new Promise((resolve, reject) => {
      CognitoService.confirmRegistration(username, code)
        .then((s) => {
          return resolve(s);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static userSession(): Promise<CognitoSession> {
    return new Promise((resolve, reject) => {
      CognitoService.userSession()
        .then((s) => {
          const currentSess = this.parseCognitoUser(s);
          sessionSubject.next(currentSess);
          return resolve(currentSess);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static getToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      CognitoService.userSession()
        .then((s) => {
          return resolve(s.token.idToken.jwtToken);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static refreshSession(): Promise<CognitoSession> {
    return new Promise((resolve, reject) => {
      CognitoService.refreshSession()
        .then((s) => {
          const currentSess = this.parseCognitoSession(s);
          sessionSubject.next(currentSess);
          return resolve(currentSess);
        })
        .catch((error) => {
          console.error(error);
          this.logout();
          reject(error);
        });
    });
  }
}

export { CognitoStore, session };
