import React from "react";

import { UserMobile } from "./UserMobile";

export interface MobileMenuFooterProps {
  clickCb: () => void;
  mobileNavOpen: boolean;
}

export const MobileMenuFooter: React.FunctionComponent<MobileMenuFooterProps> = (props: MobileMenuFooterProps) => {
  // onClick={toggleNavbarCollapse}
  return (
    <>
      {props.mobileNavOpen === true && (
        <div className="mobile-menu-footer d-lg-none">
          <UserMobile toggleCb={props.clickCb} />
        </div>
      )}
    </>
  );
};
