import { Link } from "gatsby";
import React, { useEffect } from "react";
import { Container } from "reactstrap";

export interface NoCookiesAlertProps {}
export const NoCookiesAlert: React.FunctionComponent<NoCookiesAlertProps> = () => {
  const [alerted, setAlerted] = React.useState(false);
  useEffect(() => {
    try {
      if (window.localStorage !== undefined && window.localStorage !== null) {
        return;
      }
      setAlerted(true);
    } catch (e) {
      setAlerted(true);
    }
  });
  if (alerted) {
    return (
      <div className="alert-nocookies">
        <Container className="">
          <div className="text-center">
            Psychedelic Support requires local storage and session storage to function correctly - see{" "}
            <Link to="/terms-of-service/">terms of service</Link>. Please whitelist cookies for Psychedelic Support
            domain on your browser; disabling cookies also prevents local storage and session storage.
          </div>
        </Container>
      </div>
    );
  }
  return null;
};
