/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
// tslint:disable:max-line-length
const ErrorBoundaryPage: React.FunctionComponent = () => {
  const reload = () => {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };
  return (
    <Container className="error-boundary">
      <Row>
        <Col xs="12" className="overview"></Col>
        <Col xs="12" className="overview">
          <h1>Oops! Something went wrong</h1>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          Our team is notified and we're working to fix.
          <br />
          You could try and reload this window
          <br />
          <Button color="primary" onClick={reload}>
            Reload Window
          </Button>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col className="overview">
          <h3>Or, you can try some other pages in meantime:</h3>
          <p>
            Psychedelic Support offers world class <a href="/education/">Accredited Psychedelic Education</a>
            <br />
            Not to brag! but Psychedelic Support has some great <a href="/articles/">Articles</a>
            <br />
            We've also got a network of Psychedelic Therapy <a href="/provider-network/">Providers</a>
            <br />
            Finally, the Psychedelic Community is our future - we support{" "}
            <a href="/community-directory/">Community Groups</a>
            <br />
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorBoundaryPage;
