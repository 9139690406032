/* eslint-disable import/no-unresolved */
import axios from "axios";
import React from "react";
import "react-toastify/dist/ReactToastify.min.css";
import IndexFooter from "src/components/Footers/IndexFooter";
import ErrorBoundary from "src/components/General/ErrorBoundary";
import { HubspotChat } from "src/components/General/HubspotChat";
import { NoCookiesAlert } from "src/components/General/NoCookiesAlert";
import SEO from "src/components/General/SEO";
import AdminNavbar from "src/components/Navbars/AdminNavbar";
import { SearchTopNav } from "src/components/Navbars/SearchTopNav";
import { CognitoStore } from "src/stores/cognito-store";
import { ContextProviderComponent } from "./Context";
// axios interceptor to add token to header
axios.interceptors.request.use((request) => {
  // set the Authorization header
  return new Promise((resolve) => {
    CognitoStore.getToken()
      .then((t) => {
        request.headers.common.Authorization = t;
        return resolve(request);
      })
      .catch((error) => {
        if (error !== "expired_session") {
          // swallow these errors, they'll happen a lot if the user is not signed in
          console.error(error);
        }
        return resolve(request);
      });
  });
});
// axios interceptor to catch 401 and redirect to root
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error(JSON.stringify(error));
    if (error.message === "Network Error") {
      // cant publish network error to server, no connection!
      return;
    }
    if (error.config && error.config.redirect401 === false) {
      // if redirect401 is set to false on config then ignore this error
      // let the caller handle the error
      throw error;
    }
    if (error.response && error.response.status === 401) {
      CognitoStore.logout()
        .then(() => {
          // eslint-disable-next-line no-undef
          window.location.href = "/";
        })
        .catch((error_) => {
          console.error(error_);
          // eslint-disable-next-line no-undef
          window.location.href = "/";
        });
      return;
    }
    throw error;
  },
);

CognitoStore.refreshSession()
  .then(() => {
    return console.log("refreshed session");
  })
  .catch(() => {
    console.error("unable to refresh session");
  });

// eslint-disable-next-line import/no-default-export, react/display-name
export default ({ children, pageContext, location }) => {
  if (pageContext.layout === "admin") {
    return (
      <ErrorBoundary pathName={location.pathname}>
        <ContextProviderComponent>
          <NoCookiesAlert />
          <AdminNavbar scheme="solid" />
          {children}
          <IndexFooter />
        </ContextProviderComponent>
      </ErrorBoundary>
    );
  }
  if (pageContext.layout === "my" || pageContext.layout === "users") {
    return (
      <ContextProviderComponent>
        <ErrorBoundary pathName={location.pathname}>
          <NoCookiesAlert />
          <SearchTopNav transparentNav={pageContext.transparentNav} stickyNav={pageContext.stickyNav} />
          <div className="main">{children}</div>
          <IndexFooter />
          <HubspotChat />
        </ErrorBoundary>
      </ContextProviderComponent>
    );
  }
  return (
    <>
      <ContextProviderComponent>
        <SEO />
        <ErrorBoundary pathName={location.pathname}>
          <NoCookiesAlert />
          <SearchTopNav transparentNav={pageContext.transparentNav} stickyNav={pageContext.stickyNav} />
          <div className="main">{React.Children.map(children, (child) => React.cloneElement(child, { location }))}</div>
          <IndexFooter footerBorderless={pageContext.footerBorderless} />
        </ErrorBoundary>
      </ContextProviderComponent>
    </>
  );
};
