import React from "react";
import { Container } from "reactstrap";
import { SearchComponent } from "src/components/Search/SearchComponent";
import { navigate } from "gatsby";

export interface SearchNavMobileProps {
  doneCb: () => void;
}

export const SearchNavMobile: React.FunctionComponent<SearchNavMobileProps> = (props: SearchNavMobileProps) => {
  const [searchInput, setSearchInput] = React.useState("");

  const performSearch = (s: string): void => {
    props.doneCb();
    navigate(`/search?s=${s}`);
  };
  const clearSearch = (): void => {
    // reset the state
    setSearchInput("");
  };
  return (
    <>
      <div className="search-nav">
        <Container>
          <div>
            <SearchComponent
              input={searchInput}
              inputPlaceholder={"Search"}
              searchCb={(s: string): void => performSearch(s)}
              loading={false}
              clearCb={clearSearch}
              location={"nav"}
            />
            <div className="mt-4">
              <div>Try searching for:</div>
              <div className="cursor-pointer fw400" onClick={(): void => performSearch("MDMA")}>
                <a>MDMA</a>
              </div>
              <div className="cursor-pointer fw400" onClick={(): void => performSearch("Psilocybin")}>
                <a>Psilocybin</a>
              </div>
              <div className="cursor-pointer fw400" onClick={(): void => performSearch("Ketamine")}>
                <a>Ketamine</a>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
