import axios from "axios";
import { LogMsg } from "ps-shared";

export class LogHelper {
  public static publish(e: LogMsg): void {
    axios
      .post(`${process.env.PSYSUP_API}/log`, e)
      .then(() => {
        console.log("published prod error");
      })
      .catch(() => {
        console.error("unable to publish error!");
      });
  }

  public static publishError(e: Error): void {
    const logMsg = {
      message: e.message,
      data: {
        stack: e.stack,
      },
      level: "ERROR",
    };
    axios
      .post(`${process.env.PSYSUP_API}/log`, logMsg)
      .then(() => {
        return console.log("published prod error");
      })
      .catch(() => {
        console.error("unable to publish error!");
      });
  }
}
